<template>
<div class="flexPage themeColor">
	<div class="flexHead">
		<navbar :params="pageInfo" />	
	</div>
	<div class="flexContent">
		<ul class="articleList">
            <nodata v-if="!list || list.length==0"></nodata>
			<van-pull-refresh v-model="submiting" @refresh="refresh">
				<van-list
					v-model="$store.state.app.loading"
					:finished="finished"
					@load="articles"
					:offset="50"
				>
            		<loading slot="loading" />
					<li v-for="(item,index) of list" @click="$router.push({name:'helpdetail',params:{id:item.id}})" :key="index">
						<h3><span class="dot"></span>{{item.caption}}</h3>
						<div class="date">{{item.pubTime}}</div>
					</li>
				</van-list>
			</van-pull-refresh>	
		</ul>
	</div>
</div>
</template>
<script>
import navbar from "@/views/modules/navbar"
export default {
	data(){
    	return {
    		pageNum: 0, // 当前页码
			submiting:false,
			pageInfo:{
				title: this.$t("article.help"),
				navbarStyle: 'normal'
			},
    	}
    },
  	components:{navbar},
  	watch:{},
  	computed:{
  		list(){
  			return this.$store.state.app.vanList;
  		},
  		finished(){
  			return this.$store.state.app.finished;
  		}
  	},
	mounted(){

	},
	beforeDestroy(){
		this.$mts.initPage();
	},
	methods:{
		refresh(){
			this.pageNum = 0;
            this.$mts.initPage();
            this.$store.state.app.loading = true;
            this.articles()
		},
		articles(){
			var that = this;
			that.pageNum ++;
			that.$mts.post({
				url:'article',
				data:{
					type:'HELP', // 查询通知
					pageNum: that.pageNum
				},success(response){
					if (response.data.code == 200) {
						that.$mts.loading( response.data.data);
						that.submiting = false;
					}
				}
			});
		}
	}
}
</script>
